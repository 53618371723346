/* Start of initial css */ 

html, body, header {
  margin: 0;
  padding: 0;
}

/*#root {
  margin: 0;
  padding: 0;
} */


.Landing {
  margin: 0;
   /*text-align: center;
  width: 100%;
  height: 100%;  */
  
}


.Landing-header {
  background-image: url('/src/assets/images/LandingPage2.jpg');
  height: 100%;
  width: 100%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: large;
  padding: 0; /* Added padding for smaller screens */
}


header .text-container {
  /* background-color: rgba(38, 116, 80, 0.25); Semi-transparent black background */
  padding: 100px; /* Spacing inside the rectangle */
  border-radius: 10px; /* Rounded corners of the rectangle */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto; /* Width relative to the parent */
  max-width: 600px; /* Maximum width for larger screens */
  text-align: center;
  background-color: rgba(255, 255, 255, 0.43); /* Semi-transparent black background */
  
}

.text-container {
  /*padding: 100px;  Spacing inside the rectangle */
 /*  border-radius: 10px; Rounded corners of the rectangle */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  width: auto; /* Width relative to the parent */
  /*max-width: 600px;  Maximum width for larger screens */
  /* margin: 20px; /* Centering the container 
  text-align: center; */
  position: relative;
  top: 0;
}

.Landing-header h1 ,
.Landing-header p {
  font-size:xx-large;
  font: bolder;
  margin: 0; /* Remove default margins */
  padding: 0.5em 0; /* Spacing for top and bottom inside the container */
  color: green; /* Text color */
  word-wrap: break-word; /* To handle long words on small screens */
}

header Footer{
  padding-top: 80px;
}

Footer{
  padding-bottom: 2px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
     /* Adds some space at the top on smaller screens */
  }
}

/* End of initial css */ 

/* Position and sizing of burger button */
/* .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px; 
  top: 36px;
}

/* Color/shape of burger icon bars 
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button 
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross 
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles 
.bm-menu {
  /* background: #373a47; 
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 200px; /* Set a fixed width for the menu 
  overflow: hidden; /* Prevents scrolling 
} 

/* Right align the sidebar menu 
html .bm-menu-wrap {
  right: 0;
}
/* Morph shape necessary with bubble or elastic 


/* Wrapper for item list 
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  text-align: center;
  /*overflow: hidden;  Prevents scrolling 
}

/* Individual item 
.bm-item {
  display: contents;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  overflow: hidden; /* Prevents scrolling 
}

.bm-item:hover {
  color: white;
}

/* Styling of overlay */
/* .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
} 

@media screen and (max-width: 480px) {
  .bm-burger-button {
    right: 22px; 
    top: 22px;
  }
} */


/* Second page css */
/* .about-us-content */
.about-us {
  /* background-image: url('/src/assets/images/AboutUs.jpg'); */
  background-color: green;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px; /* Added padding for smaller screens */

}

.about-us Menu {
  height: 50vh;
  overflow: hidden;
} 

/* .about-us .text-container {
  background-color: rgba(38, 116, 80, 0.25); /* Semi-transparent black background 
} */

/* Larger screens layout */
@media (min-width: 769px) {
  .text-container {
    padding: 25px;
    margin: 25px;
  }

  /* .about-us-image img {
    max-height: 100%; /* Allow the image to fill the container height 
  } */
}

/* Responsive adjustments for medium screens and smaller */
@media (max-width: 768px) {
  .text-container {
    padding: 15px;
    margin: 15px;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .text-container {
    padding: 10px;
    margin: 10px;
  }
}

/* End of css*/

/* Vision page css*/
/* .vision-page {
  display: flex;
  flex-direction: column; /* Stack the vision section's children vertically 
  justify-content: center;
  align-items: center;
  padding: 10px; /* Add padding for smaller screens 
  min-height: 100vh;
  background: #fff;
  color: #333;
  
}

.vision-section {

  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  /* display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  width: 100%;
  max-width: auto;
  margin: auto; 
}

.vision-card {
  background-color: #f9bf3b; /* Mustard yellow background 
  color: #fff; /* White text 
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.video-card {
  width: 100%; /* Takes the full width of its container 
  max-width: 600px; /* Maximum width of the video card 
  margin: 2rem auto; /* Centers the card and adds margin from the top and bottom 
  padding: 1rem; /* Padding inside the video card 
  border-radius: 8px; /* Rounded corners for the video card 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics 
  text-align: center; /* Center the video/gif inside the card 
  /* Responsive aspect ratio for the video/gif 
  overflow: hidden; /* Ensures that nothing spills out of the card 
  position: relative; /* Ensures the card is positioned in flow with other elements 
  padding-bottom: 56.25%; /* 16:9 aspect ratio 
}

.video-card img {
  width: 100%; /* Make the video/gif responsive 
  height: auto; /* Maintain aspect ratio 
  max-height: 500px; /* Maximum height of the video/gif 
}

.video-card video {
  /* Absolute position to fit the aspect ratio box 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vision-main-content {
  background-color: #fff; /* White background 
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vision-main-content h1 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.movement-goal {
  color: #3c763d; /* Green color 
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.icon-header {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.divider {
  height: 2px;
  background-color: #ccc;
  margin: 1rem 0;
}

/* Style for large screens, desktops 
@media (min-width: 1024px) {
  .vision-section {
    /* Keep the grid layout defined in the .vision-section 
    .vision-section {
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      padding: 20px;
      max-width: 1200px;
      margin: auto;
    }
    
  }
  .video-card {
    position: relative; /* Ensures the card is positioned in flow with other elements 
    width: 100%; /* Card takes the full width of its container 
    max-width: 640px; /* Maximum width of the card 
    margin: 1rem auto; /* Centers the card with a margin on the top and bottom 
    overflow: hidden; /* Ensures that nothing spills out of the card 
    border-radius: 8px; /* Optional: rounds the corners of the card 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow 
  
    /* Responsive aspect ratio for the video/GIF 
    padding-top: 56.25%; /* 16:9 aspect ratio 
  }
  
  .video-card img,
  .video-card video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will cover the area of the card without stretching 
  }
}

/* Style for tablets 
@media (min-width: 768px) and (max-width: 1023px) {
  .vision-section {
    grid-template-columns: 1fr; /* Stack the cards on medium screens 
  }
}

/* Style for mobile devices 
@media (max-width: 767px) {
  .vision-section {
    grid-template-columns: 1fr; /* Stack the cards on small screens 
    padding: 10px;
  }
  .vision-card, .vision-main-content, .video-card {
    padding: 10px; /* Less padding on small screens 
  }
  .icon {
    font-size: 1rem; /* Smaller icons for small screens 
  }
  /* Adjust font sizes and other properties as needed for readability 
  h1, h2, p {
    font-size: smaller;
  }
}

/* You can add more specific breakpoints if needed 
@media (max-width: 480px) {
  .vision-page {
    /* Adjust the main vision page styles for smaller screens 
    padding: 20px;
    
  }
  
  .vision-section {
    /* Stack the vision section's children vertically on small screens 
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .vision-card,
  .vision-main-content,
  .video-card {
    /* Allow cards to take full width minus some padding on the smallest screens 
    width: calc(100% - 10px);
    margin: 5px;
    padding: 10px;
  }

  /* .video-card {
    /* Adjust the video card to maintain aspect ratio 
    padding-top: 75%; /* Change the percentage based on the aspect ratio of your video or GIF 
  } 

  .video-card img,
  .video-card video {
    /* Ensure the video or GIF scales correctly 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Use 'contain' to ensure the entire video or GIF is visible 
  }

  .icon {
    /* Reduce the size of icons for small screens 
    font-size: 1rem;
  }

  /* Adjust text sizes for better readability on small screens 
  h1, h2, h3, p {
    font-size: smaller;
  }

  .divider {
    /* Adjust the divider for smaller screens 
    margin: 10px 0;
  }

  /* Add any additional styles you need to adjust for small screens 
} */
/* End of vision page css*


/* Our Services page css */



.services-container {
  /* background-image: url('/src/assets/images/AboutUs.jpg'); */

  display: flex;
  flex-direction: column; /* Stack image and services vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Full viewport height */
  background-color: whitesmoke; /* Light green background */
  padding: 15px; /* Uniform padding */
  margin: 0; /* Remove default margin */
  width: 100%; /* Full width */
}

/* .services-image img {
  width: 100%;
  max-width: 50vh; /* Prevent image from overflowing its container 
  object-fit: cover;
  height: auto;
  border-radius: 8px;
} */

.services-info {
  margin: auto;
  width: 100%; /* Full width */
  max-width: 960px; /* Maximum width of the content */
  align-items: center;
  /* margin-top: 2rem; Space between image and content */
  color: green;
  /* display: flex;
  flex-direction: column; */
}

.services-info h1 {
  font-size: 2rem; /* Larger font size for the heading */
  margin-bottom: 1rem;
  text-align: center; /* Center the heading */
  /* margin-bottom: 1rem; */
}

.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column; /* Stack list items vertically */
  align-items: center; /* Center list items */
  width: 100%; /* Full width */
  color: whitesmoke ; 
  /* list-style: none;
  padding: 0;
  margin: 0; */
} 

.services-list li {
  width: 100%; /* Full width for list items */
  background-color: green; /* White background for list items */
  padding: 1rem; /* Padding inside list items */
  margin-bottom: 1rem; /* Space between list items */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  display: flex;
  align-items: center;
  /* display: flex;
  align-items: center;
  margin-bottom: 1rem; */
}

.service-icon {
  color: whitesmoke; /* Matching green color for icons */
  /*color: #4caf50;  Matching green color for icons */
  margin-right: 0.5rem;
  font-size: 1.5rem; /* Larger icon size */
  /* margin-right: 0.5rem;
  color: green;
  min-width: 30px; */
}

/* Responsive adjustments */
@media (min-width: 1024px) {

  .services-container {
    flex-direction: row; /* Side-by-side layout on larger screens */
    justify-content: space-between; /* Evenly space out the image and info */
    align-items: center; /* Align to the top */
    padding: 100px; /* More padding on larger screens */
    margin: 0;
  }

  /* .services-image img {
    max-width: 40%; /* Limit image width to 40% of the viewport 
    max-height: none; /* Remove max-height limitation 
  } */

  .services-info {
    max-width: auto; /* Allocate remaining width to services info */
    margin-top: 0; /* Reset top margin */
    margin: auto;
  }

  .services-list{
    /* padding: auto; */
    margin-left: auto;
    margin-right: auto;
  }

  /* .services-image, .services-info {
    padding: 0 1rem; /* Apply horizontal padding to the image and info cards 
  } */
}

@media (max-width: 768px) {
  .services-container {
    grid-template-columns: 1fr; /* Stack vertically on smaller screens */
  }

  .services-info {
    margin-top: 2rem; /* Space between image and content */
    margin: auto;

  }

  .services-list{
    /* padding: auto; */
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  /* .services-image {
    order: -1; /* Image goes above the list on smaller screens 
  } */

  /* .services-image, .services-info {
    padding: 0 1rem; /* Apply horizontal padding to the image and info cards 
  } */
}

@media (max-width: 480px) {
  .services-container {
    padding: 1rem; /* Reduced padding on very small screens */
  }

  .services-info {
    margin: auto; 
  }

  .services-list{
    /* padding: auto; */
    margin-left: auto;
    margin-right: auto;
  }
  
  .services-info h1 {
    font-size: 1.5em; /* Smaller header font size on very small screens */
  }

  .services-list li {
    font-size: 0.9em; /* Smaller font size for list items on very small screens */
  }

  .service-icon {
    font-size: 1.25rem; /* Smaller icon size on small screens */
  }

  /* .services-image, .services-info {
    padding: 0 1rem; /* Apply horizontal padding to the image and info cards 
  } */
}

/* Services.css */

/* End of our services page css */

/* PhotoWorks page css */
.scroll-container {
  overflow-x: auto;
  background-color: green;
  padding: 20px;
  display:flex;
  margin-top: 10%;
  justify-content: center;

  /* For WebKit-based browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

  .scroll-container::-webkit-scrollbar {
    height: 8px;
  }

  .scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

/* Centering the cards on larger screens */
@media (min-width: 1024px) {
  .scroll-container {
    justify-content: center;
    padding: 20; /*Adjusts left padding */
  }

  .MuiCard-container {
    justify-content: center;
  }
  /* Individual card styling */
  .scroll-container .MuiCard-root {
    flex: 0 0 auto; /* Prevents cards from shrinking */
    margin-left: 30px; /* Margin between cards */
    display:  flex;
    flex-direction: column;
  }
}

/*  on phone screens */
@media (max-width: 767px) {
  .scroll-container {
    overflow-x: hidden;
    white-space: normal;
  }

  Button {
    margin-right: 2;
  }

  .scroll-container > * {
    display: block;
    max-width: 100%;
    margin-bottom: 20px;
    /* margin-left: auto; /* Add this line */
    /* margin-right: auto; Add this line */
  }
  

  .MuiGrid-container {
    display: flex; /* Add this line */
    flex-direction: column; /* Add this line */
    align-items: center;
    justify-content: center;
  }


  /* Individual card styling */
  .MuiCard-root {
    display: flex;
    flex-direction: column;
  }

  .MuiCardMedia-root {
    flex-grow: 1; /* Makes the image take up as much space as possible */
  }

}

/* End of our photoworks page css */

/* Start of our videoworks page css */
/* App.css */

.videowork {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  height: 80vh; /* This makes the container take up the full height of the viewport */
}

.card-content {
  background-color: green;
  color: white;
}

/* End of our videoworks page css */

/* Start of our contact-us page css */

.contact-us {
  text-align: center;
  padding: 50px;
  font-size: larger;
  background-color: #fff; /* Change to match your design */
  color: #333; /* Change to match your design */
}

.contact-us h2 {
  color: green; /* Change to match your design */
  font-size: larger;
}

/* .contact-us p {
  font-size: large;
  color: green /* Change to match your design 
} */
.contact-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.team-member {
  width: 300px; /* Adjust based on your layout needs */
}

.member-image {
  width: 200px; /* Adjust based on your layout needs */
  height: 200px; /* Adjust based on your layout needs */
  border-radius: 80%;
  background-size: cover;
  object-fit: cover; /* This makes sure the image covers the area without distortion */
  background-position: center;
  margin: 0 auto;
}

.social-media-links{
  color: green;
  font: bolder;
  font-style: normal;
  font-size: larger;
}

.social-media-icons {
  color: #333; /* Change to match your design */
  display: flex;
  justify-content: space-evenly; 
}

.fa-2x {
  font-size: 2em; /* This will increase the size of the icons */
}

.member-role {
  font-style: italic;
  color: green; /* Change to match your design */
  font-size: larger;
  font: bolder;
}

.member-bio {
  font: bolder;
  font-size: 0.9em;
  color: #333; /* Change to match your design */
}

.member-divider {
  /* height: 80%;  Or whatever width you prefer */
  border: 0; /* Removes the default border */
  height: 10px; /* Sets the height of the line */
  background-color: #333; /* Sets the color of the line */
  /* margin: 20px auto; /* Centers the line and adds some vertical spacing */
}


.container-error {
  background-color:white;
  color:  #4CAF50;
  font-family: 'Arial', sans-serif;
  text-align: center;
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
}

.container-error h1 {
  font-size: 100px;
  margin-bottom: 10px;
}

.container-error p {
  font-size: 22px;
}

.container-error a {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 25px;
  background-color: white;
  color: #4CAF50; 
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.container-error a:hover {
  background-color: #3e8e41;
  color: white;
} 


/* Responsive layout adjustments */
@media (max-width: 767px) {
  .contact-container {
    flex-direction: column;
  }
}


/* End of our contact-us page css */


/* Responsive adjustments */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }
  .text-container {
    padding: 15px;
    margin: 15px;
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(6px + 2vmin);
  }
  .text-container {
    padding: 10px;
    margin: 10px;
  }
}

/*About us css responsive page*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
